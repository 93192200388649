export const environment = {
  production: false,
  cookieName: '.AspNetCore.Cookies',
  API: 'https://reconocimientos.soyalmacen.com/api',
  //API: 'https://localhost:44396',
  Itgov:'https://soyalmacen.com/portal-interno',
  KENDO_UI_LICENSE:'eyJhbGciOiJSUzI1NiIsInR5cCI6IkxJQyJ9.eyJwcm9kdWN0cyI6W3sidHJpYWwiOmZhbHNlLCJjb2RlIjoiS0VORE9VSVJFQUNUIiwibGljZW5zZUV4cGlyYXRpb25EYXRlIjoxNjExMzgwNzUwfSx7InRyaWFsIjpmYWxzZSwiY29kZSI6IktFTkRPVUlDT01QTEVURSIsImxpY2Vuc2VFeHBpcmF0aW9uRGF0ZSI6MTYxMTM4MDc1MH0seyJ0cmlhbCI6ZmFsc2UsImNvZGUiOiJLRU5ET1VJVlVFIiwibGljZW5zZUV4cGlyYXRpb25EYXRlIjoxNjExMzgwNzUwfSx7InRyaWFsIjpmYWxzZSwiY29kZSI6IktFTkRPVUlBTkdVTEFSIiwibGljZW5zZUV4cGlyYXRpb25EYXRlIjoxNjExMzgwNzUwfV0sImludGVncml0eSI6IjJUbnNuZ3lzdGFoYVo1S1BFc3l1WGZDRmFhOD0iLCJsaWNlbnNlSG9sZGVyIjoiTUxPUEVaQEhPMUEuQ09NIiwiaWF0IjoxNjA4MzA4NjQ5LCJhdWQiOiJNTE9QRVpASE8xQS5DT00ifQ.Z2gwOBsvOcExmD-F4Nc8hLTaFYtMZFtiWeGqYvKgycBKYZ2S9QM7ALpB9ZqNHSPh2q5bXfIVJEX-1p5-yH0J1oYS0ToFidvOlp2yeShJLMEKJCovkOSK8-J2UvHslGhAf0M_iIpgwLjr7KFKTULybZ2GIyGfLgL_mohVV0og72qDDhZBWawWg9Nta770jesD0MmJ6lvfugTWxSABg35eLZGAjpn9WMBE9sq2A57iffEGMh3F3DmBV68ZspwQTEX6rNI_EmjcO-aKh0AyHOWq8lmYZk5o3rQkezc3MWwPja6HNEmQMk04aGqXYe24jSyopsnK0ie0bSBVwkGv8K689g',
  cookieUser: '_token_reconocimientos',
  // APIItGov:'https://localhost:44396/api'
  APIItGov:'https://soyalmacen.com/api',
  filespath: 'https://peopletalent-alemacendedrogas-12112024.s3.amazonaws.com/',
  logoUrl: 'perfil/638470835990029800.png'
};
